.range-slider {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.range-slider__base-bar {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  background: #3a3b3e;
  border: solid 1px #3a3b3e;
}

.range-slider__rule {
  position: absolute;
  width: 1px;
  height: calc(100% - 6px);
  background: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #ffffff;
}

.button__control {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 19px;
  color: #000;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  overflow: hidden;
  background: #fff;
}

.button__control-left {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 15px;
}

.button__control-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 15px;
}
