:focus {
  border-color: transparent;
  outline-style: none;
  box-shadow: none;
}

/* Remove tap highlight on iOS */
button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
