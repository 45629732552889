@import '../../global/variables.scss';
.stats-block__section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  width: 50%;
  height: 100%;
  padding: $medium-margin;
}

.stats-block__number {
  @include stats-number;
}

.stats-block__text {
  text-transform: uppercase;
}

.stats-block__difference {
  &.positive {
    color: $positive-green;
  }
  &.negative {
    color: $negative-red;
  }
}
