@import '../../global/variables.scss';

.link-contact__container {
  margin-top: $large-margin;
  font-size: 18px;
  font-family: Barlow, sans-serif;
  line-height: 1.33;
  text-align: center;
}

.link-contact__link {
  color: $link-green;
}
