.tabs__tab-list {
  box-sizing: border-box;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 16px;
  width: 100%;
}

.tabs__tab {
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  flex: 1;
  font-family: 'Barlow';
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.33;
  margin: 0;
  padding: 8px;
  text-align: center;
  &:focus {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: none;
    outline-style: none;
  }

  &--active {
    border-bottom: 1px solid #00ffb3;
    color: #00ffb3;
    &:focus {
      border-bottom: 1px solid #00ffb3;
      box-shadow: none;
      outline-style: none;
    }
  }

  &:hover {
    color: #00ffb3;
  }
}
