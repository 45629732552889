@import '../../global/variables.scss';

.axis path,
.axis line {
  fill: none;
  stroke: none;
}

.axis {
  font: 14px sans-serif;
}

text {
  font-size: 12px;
  font-family: $barlow;
  line-height: 1.33;
}
