@import url('https://fonts.googleapis.com/css?family=Barlow:400,700');
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700');

$barlow: 'Barlow', sans-serif;
$barlow-condensed: 'Barlow Condensed', 'sans-serif';
$dark-gray: #3a3b3e;
$light-gray: #5a5c61;
$flat-green-dark-bg: #00ffb3;
$bright-blue-dark-bg: #0076f9;
$red: #f10364;
$black: #111213;

$divider-color: $light-gray;
$block-background: $dark-gray;
$number-green: $flat-green-dark-bg;
$number-blue: $bright-blue-dark-bg;
$link-green: $flat-green-dark-bg;
$positive-green: $flat-green-dark-bg;
$negative-red: $red;
$background-black: $black;

$small-margin: 8px;
$medium-margin: 16px;
$large-margin: 24px;

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

/* ========================================== */

/**
 * shared component stypes
 */

@mixin page-container {
  padding: 16px;
  font-family: $barlow;
  font-size: 12px;
  line-height: 1.33;
  max-width: 414px;
  margin: 0 auto;
}
@mixin round-block-style {
  background-color: $block-background;
  height: 108px;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

@mixin vertical-divider {
  height: 80%;
  border: solid 1px $divider-color;
}

@mixin stats-number {
  font-size: 32px;
  line-height: 1;
  font-family: $barlow-condensed;
  font-weight: bold;
}

@mixin horizontal-divider {
  margin-top: $large-margin;
  margin-bottom: $large-margin;
  border: solid 1px $divider-color;
}
/* ========================================== */

/**
 * breakpoints
 */

$for-phone-only: 599px;
$for-tablet-portrait-up: 600px;
$for-tablet-landscape-up: 900px;
$for-desktop-up: 1200px;
$for-big-desktop-up: 1800px;
