.back-arrow {
  font-size: 30px;
  display: inline-block;
  border: none;
  background: none;
  color: #fff;
  cursor: pointer;
  z-index: 11;
  position: absolute;
  height: 60px;
  top: -33px;
  left: 0px;
  > * {
    height: 25px;
    width: 25px;
  }
}
