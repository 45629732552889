@import '../../global/variables.scss';

.menu-list__container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  cursor: pointer;
  @media (min-width: $for-desktop-up) {
    height: 80px;
    width: 25%;
  }
  ul {
    list-style: none;
    padding: 0px;
    text-align: center;
    @media (max-width: $for-phone-only) {
      position: absolute;
      top: 50px;
      width: 100vw;
    }
    @media (min-width: $for-desktop-up) {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      height: 80px;
      justify-content: space-between;
    }
    > li {
      margin: 48px auto;
      @media (min-width: $for-desktop-up) {
        margin: 0px;
      }
    }
  }
  .menu-list__logo {
    @media (min-width: $for-desktop-up) {
      display: none;
    }
  }
}
