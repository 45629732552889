@import '../../global/variables.scss';
.login__container {
  display: flex;
  flex-direction: column;
  @include page-container;
  @media (min-width: 600px) {
    margin: auto;
    width: 60%;
  }
  @media (min-width: 769px) {
    margin: auto;
    width: 50%;
  }
  @media (min-width: 1199px) {
    margin: auto;
    width: 30%;
  }
  .login__error {
    text-align: center;
    font-size: 16px;
    margin-bottom: 0px;
    color: $negative-red;
  }

  .login__container-title {
    margin-top: 40px;
    font-size: 40px;
    font-family: $barlow-condensed;
    line-height: 1.1;
    margin-bottom: 0px;
    text-align: center;
  }
  .login__container-subtitle {
    margin: 40px 20px;
    text-align: center;
    font-size: 21px;
    font-weight: normal;
  }
  .login__container-contact {
    margin: 18px 20px;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
  }
  .login__contact-link {
    color: $link-green;
    margin: 0px 5px;
  }
}
