.burger-button {
  display: inline-block;
  height: 44px;
  cursor: pointer;
  z-index: 10;
  background: none;
  border: none;
  position: absolute;
  top: -11.5px;
  right: 5px;
}

.burger-button__bar {
  width: 28px;
  height: 3px;
  background-color: #fff;
  margin: 7px 0;
  transition: 0.3s;
}

.burger-button.open .top {
  -webkit-transform: rotate(-45deg) translate(-7px, 7px);
  transform: rotate(-45deg) translate(-7px, 7px);
}

.burger-button.open .middle {
  opacity: 0;
}

.burger-button.open .bottom {
  -webkit-transform: rotate(45deg) translate(-7px, -7px);
  transform: rotate(45deg) translate(-7px, -7px);
}
