@import '../../global/variables.scss';

.dashboard__page {
  @include page-container;
  @media (min-width: 1199px) {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .dashboard__page-left {
    @media (min-width: 1199px) {
      width: 60%;
      margin-left: auto;
    }
    .title {
      width: 100%;
      font-size: 40px;
      font-family: $barlow-condensed;
      text-align: center;
      @media (min-width: 1199px) {
        display: none;
      }
    }

    .subscribers__blocks-month {
      text-transform: uppercase;
    }
  }
  .dashboard__page-right {
    width: 60%;
    height: 555px;
    margin-top: 45px;
    margin-left: 10px;
    border: 1px solid #00ffb3;
    border-radius: 10px;
    position: relative;

    @media (max-width: 1200px) {
      display: none;
    }

    .subscribers__blocks-month {
      margin-left: 25px;
    }
    .dashboard__page-right-month {
      font-family: $barlow;
      font-size: 12px;
      position: absolute;
      margin: 0px;
      text-transform: uppercase;
      top: -30px;
    }
  }
}
