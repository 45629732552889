@import '../../global/variables.scss';

.dashboard-subscribers__block {
  @include round-block-style;
  @media (min-width: 1199px) {
    display: none;
  }
}

.dashboard-subscribers__divider {
  @include vertical-divider;
}

.dashboard-subscribers__block-desktop {
  @include round-block-style;
  @media (max-width: 1200px) {
    display: none;
  }
  @media (min-width: 1199px) {
    margin-right: 10px;
  }
}
