@import '../../global/variables.scss';

.subscribers-graph__container {
  @include page-container;
}
.subscribers__graph {
  margin-top: $large-margin;
  .subscribers__number {
    display: flex;
    margin-top: $small-margin;
    align-items: flex-end;
    .subscribers__number-text {
      margin-left: $small-margin;
    }
  }
}
