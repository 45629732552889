@import '../../global/variables.scss';

.header {
  min-height: 60px;
}

.header__mobile {
  width: 100%;
  height: 60px;
  position: relative;
  overflow: hidden;
  display: flex;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  // box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 0.16),
  //   0 2px 10px 5px rgba(0, 0, 0, 0.12);
  font-size: 14px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 700;
  justify-content: space-between;
  font-family: 'Open Sans';
  .header-container.open {
    font-size: 16px;
    font-family: $barlow;
    font-weight: 400;
    font-style: normal;
    position: fixed;
    z-index: 10;
    margin: auto;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  @media (min-width: $for-desktop-up) {
    display: none;
  }
}

.header__web {
  //want to not display for any size below desktop
  @media (max-width: 1199px) {
    display: none;
  }
  max-width: 100%;
  height: 80px;
  font-family: $barlow;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8%;
  font-family: $barlow;
  font-size: 18px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  // box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 0.16),
  //   0 2px 10px 5px rgba(0, 0, 0, 0.12);
  .header__web-logo {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 700;
    justify-content: center;
    font-family: 'Open Sans';
    > h1 {
      margin-left: 12px;
      @media (min-width: 1199px) {
        margin-left: 150px;
      }
    }
  }
}

.burger-button.open {
  right: 50%;
  position: absolute;
  top: 220px;
  // kinda magic number, would need to find a better solution to center the closing button
  margin-right: -10px;
}

// .burger-button.close {
//   display: flex;
//   width: 100%;
// }

.header-title {
  width: 150px;
  margin: 0 auto;
  text-align: center;
  height: 60px;
  font-size: 28px;
  position: absolute;
  left: 50%;
  top: 18px;
  transform: translate(-50%, -50%);
  @media (min-width: 1199px) {
    top: 52px;
    width: 10%;
    left: 16%;
  }
}

.header__links {
  display: none;
}
.header__links.open {
  position: fixed;
  z-index: 11;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  div {
    margin: auto;
  }
}
