.contact-page-container {
  position: relative;
}

.contact-page-inner-left {
  width: 95%;
}

.contact-page-inner-right {
  width: 80%;
  margin-right: 40px;
  margin-top: -9px;
}

.contact-page-list-container {
  padding-inline-start: 0;
  @media (min-width: 1200px) {
    width: 380px;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 0;
  }
}

.contact-page-list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.contact-title {
  font-size: 40px;
  font-weight: bold;
  font-family: 'Barlow Condensed';
  color: #ffffff;
  text-align: center;

  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;

  margin-block-start: 24px;
  margin-block-start: 24px;
}

.contact-title-sub {
  font-family: Barlow;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;

  @media (max-width: 1199px) {
    width: 318px;
    margin-bottom: 24px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 40px;
  }
}
