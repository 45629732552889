.contact-list-item {
  width: 100%;
  background-color: #3a3b3e;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
  list-style: none;
  flex: 1;

  margin-bottom: 8px;
  vertical-align: middle;
  .contact-list-item-icon {
    transition: transform 0.08s ease-in-out;
  }
  @media (min-width: 1200px) {
    border-radius: 20px;
    width: 380px;
    border-radius: 10px;
    border: solid 1px #3a3b3e;
  }
}

.contact-list-item.selected {
  @media (min-width: 1200px) {
    border: solid 1px #00ffb3;

    .contact-list-item-icon {
      transform: rotate(90deg);
    }
  }
}

.contact-list-item-title {
  font-family: Barlow;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;

  display: inline-block;
  width: 80%;
  padding-left: 30px;
}

.contact-list-item-icon {
  color: #ffffff;
  display: inline-block;
  transform: rotate(-90deg);
}
