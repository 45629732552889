body {
  margin: 0;
  padding: 0;
  background: #111213;
  color: #fff;
}

button:focus {
  text-decoration: none;
  outline: none;
  border: none;
  box-shadow: none;
}

button::-moz-focus-inner {
  border: 0;
}

input[type='button'] {
  outline: none;
}

input[type='button']::-moz-focus-inner {
  border: 0;
}
