.loginPageForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .loginPageForm__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .loginPageForm__container-input {
      width: 90%;
      margin: 10px auto;
      padding: 5px 5px;
      color: #fff;
      font-size: 18px;
      background-color: transparent;
      border: none;
      border-bottom: solid #fff 1px;
      &:focus {
        border-bottom: solid #fff 2px;
      }
    }
  }
}
