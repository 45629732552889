.contact-person-page-wrapper {
  margin-top: 24px;
  padding-left: 16px;
  @media (min-width: 1200px) {
    float: left;
    width: 380px;
    height: 457px;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #00ffb3;
    background-color: #3a3b3e;
    padding-top: 24px;
    padding-left: 24px;
  }
}

.contact-person-header-section {
  height: 80px;
}

.contact-person-avatar {
  float: left;
  margin-right: 24px;
}

.contact-person-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}

.contact-person-name-title {
  float: left;
}

.contact-person-name {
  font-family: 'Barlow Condensed';
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;

  margin: 0;
}

.contact-person-title {
  font-family: Barlow;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0;
  // this is needed because on iphone SE, it breaks the text
  @media (max-width: 350px) {
    font-size: 16px;
  }
}

.contact-person-sub-title {
  font-family: Barlow;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
}

.contact-person-checklist {
  padding: 0;
  list-style: none;
  margin: 0;
}

.contact-person-checklist-item {
  font-family: Barlow;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;

  margin: 0;
}

.contact-person-checklist-icon {
  color: #00ffb3;
  padding-right: 16px;
}

.contact-person-email-button {
  width: 207.2px;
  height: 63.6px;
  border-radius: 100px;
  background-image: linear-gradient(to right, #00bd98, #28d95a);
  box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
  display: block;
  text-decoration: none;
  margin: 40px auto;
  line-height: 60px;
  font-family: 'Barlow Condensed';
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  /* font-stretch: condensed; */
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
