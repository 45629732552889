@import '../../global/variables.scss';
.history-page__container {
  @include page-container;
  @media (min-width: 1199px) {
    height: 50%;
    padding-top: 0px;
  }
}

.history-page__month {
  margin-top: $large-margin;
  margin-bottom: $large-margin;
  text-transform: uppercase;
  @media (min-width: 1199px) {
    display: none;
  }
}

.history-page__asterisk {
  margin-top: $medium-margin;
}

.history-page__divider {
  @include horizontal-divider;
}
