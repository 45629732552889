@import '../../global/variables.scss';

.graph-number__blocks {
  display: flex;
  min-height: 52px;
  @media (min-width: 1199px) {
    margin-top: 25px;
  }
}

.graph-number__block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: $medium-margin;
}

.graph-number__divider {
  min-height: 100%;
  margin-right: $medium-margin;
  border: 1px solid $divider-color;
}

.graph-number__number {
  @include stats-number;
  &.left {
    color: $number-green;
  }
  &.right {
    color: $number-blue;
  }
}

.graph-number__text {
  line-height: 1;
  margin-top: 8px;
  text-transform: uppercase;
}
