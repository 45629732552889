@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
body {
  margin: 0;
  padding: 0;
  background: #111213;
  color: #fff;
}

button:focus {
  text-decoration: none;
  outline: none;
  border: none;
  box-shadow: none;
}

button::-moz-focus-inner {
  border: 0;
}

input[type='button'] {
  outline: none;
}

input[type='button']::-moz-focus-inner {
  border: 0;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/OpenSans-Regular.77ff7c7f.eot);
  src: local('Open Sans'), local('OpenSans'),
    url(/static/media/OpenSans-Regular.77ff7c7f.eot) format('embedded-opentype'),
    url(/static/media/OpenSans-Regular.690d5f0d.woff) format('woff'),
    url(/static/media/OpenSans-Regular.629a55a7.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/OpenSans-Light.1112689d.eot);
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url(/static/media/OpenSans-Light.1112689d.eot) format('embedded-opentype'),
    url(/static/media/OpenSans-Light.c335fd20.woff) format('woff'),
    url(/static/media/OpenSans-Light.1bf71be1.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/OpenSans-Bold.36bf3ad0.eot);
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url(/static/media/OpenSans-Bold.36bf3ad0.eot) format('embedded-opentype'),
    url(/static/media/OpenSans-Bold.ad90b651.woff) format('woff'),
    url(/static/media/OpenSans-Bold.50145685.ttf) format('truetype');
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.header {
  min-height: 60px; }

.header__mobile {
  width: 100%;
  height: 60px;
  position: relative;
  overflow: hidden;
  display: flex;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 700;
  justify-content: space-between;
  font-family: 'Open Sans'; }
  .header__mobile .header-container.open {
    font-size: 16px;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    font-style: normal;
    position: fixed;
    z-index: 10;
    margin: auto;
    width: 100vw;
    height: 100vh;
    background-color: #000; }
  @media (min-width: 1200px) {
    .header__mobile {
      display: none; } }

.header__web {
  max-width: 100%;
  height: 80px;
  font-family: "Barlow", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8%;
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1); }
  @media (max-width: 1199px) {
    .header__web {
      display: none; } }
  .header__web .header__web-logo {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 700;
    justify-content: center;
    font-family: 'Open Sans'; }
    .header__web .header__web-logo > h1 {
      margin-left: 12px; }
      @media (min-width: 1199px) {
        .header__web .header__web-logo > h1 {
          margin-left: 150px; } }

.burger-button.open {
  right: 50%;
  position: absolute;
  top: 220px;
  margin-right: -10px; }

.header-title {
  width: 150px;
  margin: 0 auto;
  text-align: center;
  height: 60px;
  font-size: 28px;
  position: absolute;
  left: 50%;
  top: 18px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  @media (min-width: 1199px) {
    .header-title {
      top: 52px;
      width: 10%;
      left: 16%; } }

.header__links {
  display: none; }

.header__links.open {
  position: fixed;
  z-index: 11;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw; }
  .header__links.open div {
    margin: auto; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.dashboard__page {
  padding: 16px;
  font-family: "Barlow", sans-serif;
  font-size: 12px;
  line-height: 1.33;
  max-width: 414px;
  margin: 0 auto; }
  @media (min-width: 1199px) {
    .dashboard__page {
      display: grid;
      grid-template-columns: 50% 50%; } }
  @media (min-width: 1199px) {
    .dashboard__page .dashboard__page-left {
      width: 60%;
      margin-left: auto; } }
  .dashboard__page .dashboard__page-left .title {
    width: 100%;
    font-size: 40px;
    font-family: "Barlow Condensed", "sans-serif";
    text-align: center; }
    @media (min-width: 1199px) {
      .dashboard__page .dashboard__page-left .title {
        display: none; } }
  .dashboard__page .dashboard__page-left .subscribers__blocks-month {
    text-transform: uppercase; }
  .dashboard__page .dashboard__page-right {
    width: 60%;
    height: 555px;
    margin-top: 45px;
    margin-left: 10px;
    border: 1px solid #00ffb3;
    border-radius: 10px;
    position: relative; }
    @media (max-width: 1200px) {
      .dashboard__page .dashboard__page-right {
        display: none; } }
    .dashboard__page .dashboard__page-right .subscribers__blocks-month {
      margin-left: 25px; }
    .dashboard__page .dashboard__page-right .dashboard__page-right-month {
      font-family: "Barlow", sans-serif;
      font-size: 12px;
      position: absolute;
      margin: 0px;
      text-transform: uppercase;
      top: -30px; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.axis path,
.axis line {
  fill: none;
  stroke: none; }

.axis {
  font: 14px sans-serif; }

text {
  font-size: 12px;
  font-family: "Barlow", sans-serif;
  line-height: 1.33; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.desktop__page {
  padding: 16px;
  font-family: "Barlow", sans-serif;
  font-size: 12px;
  line-height: 1.33;
  max-width: 414px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }

.subscribers__blocks-month {
  display: flex;
  align-items: center;
  text-transform: uppercase; }
  @media (min-width: 1199px) {
    .subscribers__blocks-month {
      margin-top: 0px; } }
  .subscribers__blocks-month .blocks-month__icon {
    margin-right: 5px; }

.subscribers__blocks-payout {
  text-transform: uppercase; }
  @media (min-width: 1199px) {
    .subscribers__blocks-payout {
      margin-top: 0px; } }

.desktop__title {
  width: 100%;
  font-size: 40px;
  font-family: "Barlow Condensed", "sans-serif";
  text-align: center; }
  @media (min-width: 1199px) {
    .desktop__title {
      display: none; } }

.desktop__link-style {
  text-decoration: none; }

.contact-page-container {
  position: relative; }

.contact-page-inner-left {
  width: 95%; }

.contact-page-inner-right {
  width: 80%;
  margin-right: 40px;
  margin-top: -9px; }

.contact-page-list-container {
  -webkit-padding-start: 0;
          padding-inline-start: 0; }
  @media (min-width: 1200px) {
    .contact-page-list-container {
      width: 380px;
      margin-left: auto;
      margin-right: 20px;
      margin-top: 0; } }

.contact-page-list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column; }

.contact-title {
  font-size: 40px;
  font-weight: bold;
  font-family: 'Barlow Condensed';
  color: #ffffff;
  text-align: center;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  -webkit-margin-before: 24px;
          margin-block-start: 24px;
  margin-block-start: 24px; }

.contact-title-sub {
  font-family: Barlow;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0 auto; }
  @media (max-width: 1199px) {
    .contact-title-sub {
      width: 318px;
      margin-bottom: 24px; } }
  @media (min-width: 1200px) {
    .contact-title-sub {
      margin-bottom: 40px; } }

.contact-list-item {
  width: 100%;
  background-color: #3a3b3e;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
  list-style: none;
  flex: 1 1;
  margin-bottom: 8px;
  vertical-align: middle; }
  .contact-list-item .contact-list-item-icon {
    -webkit-transition: -webkit-transform 0.08s ease-in-out;
    transition: -webkit-transform 0.08s ease-in-out;
    transition: transform 0.08s ease-in-out;
    transition: transform 0.08s ease-in-out, -webkit-transform 0.08s ease-in-out; }
  @media (min-width: 1200px) {
    .contact-list-item {
      border-radius: 20px;
      width: 380px;
      border-radius: 10px;
      border: solid 1px #3a3b3e; } }

@media (min-width: 1200px) {
  .contact-list-item.selected {
    border: solid 1px #00ffb3; }
    .contact-list-item.selected .contact-list-item-icon {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); } }

.contact-list-item-title {
  font-family: Barlow;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  display: inline-block;
  width: 80%;
  padding-left: 30px; }

.contact-list-item-icon {
  color: #ffffff;
  display: inline-block;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.contact-person-page-wrapper {
  margin-top: 24px;
  padding-left: 16px; }
  @media (min-width: 1200px) {
    .contact-person-page-wrapper {
      float: left;
      width: 380px;
      height: 457px;
      border-radius: 10px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
      border: solid 1px #00ffb3;
      background-color: #3a3b3e;
      padding-top: 24px;
      padding-left: 24px; } }

.contact-person-header-section {
  height: 80px; }

.contact-person-avatar {
  float: left;
  margin-right: 24px; }

.contact-person-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 40px; }

.contact-person-name-title {
  float: left; }

.contact-person-name {
  font-family: 'Barlow Condensed';
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0; }

.contact-person-title {
  font-family: Barlow;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0; }
  @media (max-width: 350px) {
    .contact-person-title {
      font-size: 16px; } }

.contact-person-sub-title {
  font-family: Barlow;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff; }

.contact-person-checklist {
  padding: 0;
  list-style: none;
  margin: 0; }

.contact-person-checklist-item {
  font-family: Barlow;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0; }

.contact-person-checklist-icon {
  color: #00ffb3;
  padding-right: 16px; }

.contact-person-email-button {
  width: 207.2px;
  height: 63.6px;
  border-radius: 100px;
  background-image: -webkit-linear-gradient(left, #00bd98, #28d95a);
  background-image: linear-gradient(to right, #00bd98, #28d95a);
  box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
  display: block;
  text-decoration: none;
  margin: 40px auto;
  line-height: 60px;
  font-family: 'Barlow Condensed';
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  /* font-stretch: condensed; */
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.dashboard-subscribers__block {
  background-color: #3a3b3e;
  height: 108px;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center; }
  @media (min-width: 1199px) {
    .dashboard-subscribers__block {
      display: none; } }

.dashboard-subscribers__divider {
  height: 80%;
  border: solid 1px #5a5c61; }

.dashboard-subscribers__block-desktop {
  background-color: #3a3b3e;
  height: 108px;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center; }
  @media (max-width: 1200px) {
    .dashboard-subscribers__block-desktop {
      display: none; } }
  @media (min-width: 1199px) {
    .dashboard-subscribers__block-desktop {
      margin-right: 10px; } }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.login__container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-family: "Barlow", sans-serif;
  font-size: 12px;
  line-height: 1.33;
  max-width: 414px;
  margin: 0 auto; }
  @media (min-width: 600px) {
    .login__container {
      margin: auto;
      width: 60%; } }
  @media (min-width: 769px) {
    .login__container {
      margin: auto;
      width: 50%; } }
  @media (min-width: 1199px) {
    .login__container {
      margin: auto;
      width: 30%; } }
  .login__container .login__error {
    text-align: center;
    font-size: 16px;
    margin-bottom: 0px;
    color: #f10364; }
  .login__container .login__container-title {
    margin-top: 40px;
    font-size: 40px;
    font-family: "Barlow Condensed", "sans-serif";
    line-height: 1.1;
    margin-bottom: 0px;
    text-align: center; }
  .login__container .login__container-subtitle {
    margin: 40px 20px;
    text-align: center;
    font-size: 21px;
    font-weight: normal; }
  .login__container .login__container-contact {
    margin: 18px 20px;
    text-align: center;
    font-size: 18px;
    font-weight: normal; }
  .login__container .login__contact-link {
    color: #00ffb3;
    margin: 0px 5px; }

.loginPageForm {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .loginPageForm .loginPageForm__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; }
    .loginPageForm .loginPageForm__container .loginPageForm__container-input {
      width: 90%;
      margin: 10px auto;
      padding: 5px 5px;
      color: #fff;
      font-size: 18px;
      background-color: transparent;
      border: none;
      border-bottom: solid #fff 1px; }
      .loginPageForm .loginPageForm__container .loginPageForm__container-input:focus {
        border-bottom: solid #fff 2px; }

.range-slider {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.range-slider__base-bar {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  background: #3a3b3e;
  border: solid 1px #3a3b3e;
}

.range-slider__rule {
  position: absolute;
  width: 1px;
  height: calc(100% - 6px);
  background: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #ffffff;
}

.button__control {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 32px;
  height: 19px;
  color: #000;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  overflow: hidden;
  background: #fff;
}

.button__control-left {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 15px;
}

.button__control-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 15px;
}

.graph-with-slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.user-payout__container {
  margin-top: 16px; }

.user-payout__block {
  background-color: #3a3b3e;
  height: 108px;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center; }
  @media (min-width: 1199px) {
    .user-payout__block {
      margin-right: 10px; } }

.user-payout__divider {
  height: 80%;
  border: solid 1px #5a5c61; }

.user-payout__asterisk {
  margin-top: 16px; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.link-contact__container {
  margin-top: 24px;
  font-size: 18px;
  font-family: Barlow, sans-serif;
  line-height: 1.33;
  text-align: center; }

.link-contact__link {
  color: #00ffb3; }

.tabs__tab-list {
  box-sizing: border-box;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 16px;
  width: 100%; }

.tabs__tab {
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  flex: 1 1;
  font-family: 'Barlow';
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.33;
  margin: 0;
  padding: 8px;
  text-align: center; }
  .tabs__tab:focus {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: none;
    outline-style: none; }
  .tabs__tab--active {
    border-bottom: 1px solid #00ffb3;
    color: #00ffb3; }
    .tabs__tab--active:focus {
      border-bottom: 1px solid #00ffb3;
      box-shadow: none;
      outline-style: none; }
  .tabs__tab:hover {
    color: #00ffb3; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.history-page__container {
  padding: 16px;
  font-family: "Barlow", sans-serif;
  font-size: 12px;
  line-height: 1.33;
  max-width: 414px;
  margin: 0 auto; }
  @media (min-width: 1199px) {
    .history-page__container {
      height: 50%;
      padding-top: 0px; } }

.history-page__month {
  margin-top: 24px;
  margin-bottom: 24px;
  text-transform: uppercase; }
  @media (min-width: 1199px) {
    .history-page__month {
      display: none; } }

.history-page__asterisk {
  margin-top: 16px; }

.history-page__divider {
  margin-top: 24px;
  margin-bottom: 24px;
  border: solid 1px #5a5c61; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.user-payout__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-top: 24px; }
  .user-payout__container .user-payout__container-grid {
    display: flex;
    justify-content: flex-start; }
    @media (min-width: 1199px) {
      .user-payout__container .user-payout__container-grid {
        margin-top: -24px; } }
    .user-payout__container .user-payout__container-grid .user-payout__container-grid-left {
      padding-right: 16px;
      border-right: solid 1px #5a5c61; }
    .user-payout__container .user-payout__container-grid .user-payout__container-grid-center {
      padding-left: 16px;
      padding-right: 16px;
      border-right: solid 1px #5a5c61; }
    .user-payout__container .user-payout__container-grid .user-payout__container-grid-right {
      padding-left: 16px; }
    .user-payout__container .user-payout__container-grid .number-green {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 65px; }
      .user-payout__container .user-payout__container-grid .number-green > :first-child {
        font-size: 32px;
        line-height: 1;
        font-family: "Barlow Condensed", "sans-serif";
        font-weight: bold;
        margin-bottom: 0px;
        margin-top: 0px;
        color: #00ffb3; }
      .user-payout__container .user-payout__container-grid .number-green > :nth-child(2) {
        margin-top: 8px;
        text-transform: uppercase; }
    .user-payout__container .user-payout__container-grid .number-blue {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 65px; }
      .user-payout__container .user-payout__container-grid .number-blue > :first-child {
        font-size: 32px;
        line-height: 1;
        font-family: "Barlow Condensed", "sans-serif";
        font-weight: bold;
        margin-bottom: 0px;
        margin-top: 0px;
        color: #0076f9; }
      .user-payout__container .user-payout__container-grid .number-blue > :nth-child(2) {
        margin-top: 8px;
        text-transform: uppercase; }
    .user-payout__container .user-payout__container-grid .number-white {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 65px; }
      .user-payout__container .user-payout__container-grid .number-white > :first-child {
        font-size: 32px;
        line-height: 1;
        font-family: "Barlow Condensed", "sans-serif";
        font-weight: bold;
        margin-bottom: 0px;
        margin-top: 0px;
        color: "white"; }
      .user-payout__container .user-payout__container-grid .number-white > :nth-child(2) {
        margin-top: 8px;
        text-transform: uppercase; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.subscribers-graph__container {
  padding: 16px;
  font-family: "Barlow", sans-serif;
  font-size: 12px;
  line-height: 1.33;
  max-width: 414px;
  margin: 0 auto; }

.subscribers__graph {
  margin-top: 24px; }
  .subscribers__graph .subscribers__number {
    display: flex;
    margin-top: 8px;
    align-items: flex-end; }
    .subscribers__graph .subscribers__number .subscribers__number-text {
      margin-left: 8px; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.graph-number__blocks {
  display: flex;
  min-height: 52px; }
  @media (min-width: 1199px) {
    .graph-number__blocks {
      margin-top: 25px; } }

.graph-number__block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 16px; }

.graph-number__divider {
  min-height: 100%;
  margin-right: 16px;
  border: 1px solid #5a5c61; }

.graph-number__number {
  font-size: 32px;
  line-height: 1;
  font-family: "Barlow Condensed", "sans-serif";
  font-weight: bold; }
  .graph-number__number.left {
    color: #00ffb3; }
  .graph-number__number.right {
    color: #0076f9; }

.graph-number__text {
  line-height: 1;
  margin-top: 8px;
  text-transform: uppercase; }

.burger-button {
  display: inline-block;
  height: 44px;
  cursor: pointer;
  z-index: 10;
  background: none;
  border: none;
  position: absolute;
  top: -11.5px;
  right: 5px; }

.burger-button__bar {
  width: 28px;
  height: 3px;
  background-color: #fff;
  margin: 7px 0;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.burger-button.open .top {
  -webkit-transform: rotate(-45deg) translate(-7px, 7px);
  transform: rotate(-45deg) translate(-7px, 7px); }

.burger-button.open .middle {
  opacity: 0; }

.burger-button.open .bottom {
  -webkit-transform: rotate(45deg) translate(-7px, -7px);
  transform: rotate(45deg) translate(-7px, -7px); }

.back-arrow {
  font-size: 30px;
  display: inline-block;
  border: none;
  background: none;
  color: #fff;
  cursor: pointer;
  z-index: 11;
  position: absolute;
  height: 60px;
  top: -33px;
  left: 0px; }
  .back-arrow > * {
    height: 25px;
    width: 25px; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.menu-list__container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  cursor: pointer; }
  @media (min-width: 1200px) {
    .menu-list__container {
      height: 80px;
      width: 25%; } }
  .menu-list__container ul {
    list-style: none;
    padding: 0px;
    text-align: center; }
    @media (max-width: 599px) {
      .menu-list__container ul {
        position: absolute;
        top: 50px;
        width: 100vw; } }
    @media (min-width: 1200px) {
      .menu-list__container ul {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 80px;
        justify-content: space-between; } }
    .menu-list__container ul > li {
      margin: 48px auto; }
      @media (min-width: 1200px) {
        .menu-list__container ul > li {
          margin: 0px; } }
  @media (min-width: 1200px) {
    .menu-list__container .menu-list__logo {
      display: none; } }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.fitplan-logo {
  display: flex;
  flex-direction: column;
  margin-left: calc(50% - 17px);
  z-index: 10;
  background: none;
  border: none; }
  @media (min-width: 1200px) {
    .fitplan-logo {
      margin-left: 0px; } }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.payout-blocks__container {
  overflow: hidden; }
  .payout-blocks__container ::-webkit-scrollbar {
    width: 0px; }
  .payout-blocks__container .payout-blocks__blocks {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    max-width: 100%; }
    .payout-blocks__container .payout-blocks__blocks .payout-blocks__block {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      min-width: 130px;
      height: 120px;
      margin-right: 8px;
      background-color: #3a3b3e;
      border-radius: 10px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5); }
      .payout-blocks__container .payout-blocks__blocks .payout-blocks__block .payout-blocks__month {
        margin-bottom: 16px;
        text-transform: uppercase; }
      .payout-blocks__container .payout-blocks__blocks .payout-blocks__block .payout-blocks__amount {
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 32px;
        font-family: "Barlow Condensed", "sans-serif";
        line-height: 1.25px; }

.payout-blocks__container-desktop .payout-blocks__blocks-desktop {
  display: flex;
  flex-direction: row;
  display: grid;
  grid-template-columns: 33% 33% 33%; }
  .payout-blocks__container-desktop .payout-blocks__blocks-desktop .payout-blocks__block-desktop {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    max-width: 240px;
    min-height: 120px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #3a3b3e;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5); }
    .payout-blocks__container-desktop .payout-blocks__blocks-desktop .payout-blocks__block-desktop .payout-blocks__month-desktop {
      margin-bottom: 16px;
      text-transform: uppercase; }
    .payout-blocks__container-desktop .payout-blocks__blocks-desktop .payout-blocks__block-desktop .payout-blocks__amount-desktop {
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 32px;
      font-family: "Barlow Condensed", "sans-serif";
      line-height: 1.25px; }

.payout-blocks__container .Carousel__itemsList .Carousel__slide.payout-blocks__block {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 120px;
  margin-right: 5px;
  background-color: #3a3b3e;
  min-width: inherit;
  border-radius: 10px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
  width: calc(33% - 5px); }
  .payout-blocks__container .Carousel__itemsList .Carousel__slide.payout-blocks__block a {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    text-decoration: none; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.stats-block__section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  width: 50%;
  height: 100%;
  padding: 16px; }

.stats-block__number {
  font-size: 32px;
  line-height: 1;
  font-family: "Barlow Condensed", "sans-serif";
  font-weight: bold; }

.stats-block__text {
  text-transform: uppercase; }

.stats-block__difference.positive {
  color: #00ffb3; }

.stats-block__difference.negative {
  color: #f10364; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

/* ========================================== */
/**
 * shared component stypes
 */
/* ========================================== */
/**
 * breakpoints
 */
.desktop__page {
  padding: 16px;
  font-family: "Barlow", sans-serif;
  font-size: 12px;
  line-height: 1.33;
  max-width: 414px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }

.subscribers__blocks-month {
  text-transform: uppercase; }
  @media (min-width: 1199px) {
    .subscribers__blocks-month {
      margin-top: 0px; } }

.subscribers__blocks-payout {
  text-transform: uppercase; }
  @media (min-width: 1199px) {
    .subscribers__blocks-payout {
      margin-top: 0px; } }

.desktop__title {
  width: 100%;
  font-size: 40px;
  font-family: "Barlow Condensed", "sans-serif";
  text-align: center; }
  @media (min-width: 1199px) {
    .desktop__title {
      display: none; } }

.desktop__link-style {
  text-decoration: none; }

:focus {
  border-color: transparent;
  outline-style: none;
  box-shadow: none;
}

/* Remove tap highlight on iOS */
button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

