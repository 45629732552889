@import '../../global/variables.scss';

.desktop__page {
  @include page-container;
  display: flex;
  flex-direction: column;
}

.subscribers__blocks-month {
  display: flex;
  // justify-content: center;
  align-items: center;
  text-transform: uppercase;
  @media (min-width: 1199px) {
    margin-top: 0px;
  }
  .blocks-month__icon {
    margin-right: 5px;
  }
}

.subscribers__blocks-payout {
  text-transform: uppercase;
  @media (min-width: 1199px) {
    margin-top: 0px;
  }
}

.desktop__title {
  width: 100%;
  font-size: 40px;
  font-family: $barlow-condensed;
  text-align: center;
  @media (min-width: 1199px) {
    display: none;
  }
}

.desktop__link-style {
  text-decoration: none;
}
