@import '../../global/variables.scss';

.user-payout__container {
  margin-top: $medium-margin;
}
.user-payout__block {
  @include round-block-style;
  @media (min-width: 1199px) {
    margin-right: 10px;
  }
}

.user-payout__divider {
  @include vertical-divider;
}

.user-payout__asterisk {
  margin-top: $medium-margin;
}
