@import '../../global/variables.scss';
.payout-blocks__container {
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 0px;
  }

  .payout-blocks__blocks {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    max-width: 100%;
    .payout-blocks__block {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      min-width: 130px;
      height: 120px;
      margin-right: 8px;
      background-color: $block-background;
      border-radius: 10px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
      .payout-blocks__month {
        margin-bottom: 16px;
        text-transform: uppercase;
      }
      .payout-blocks__amount {
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 32px;
        font-family: $barlow-condensed;
        line-height: 1.25px;
      }
    }
  }
}

.payout-blocks__container-desktop {
  .payout-blocks__blocks-desktop {
    display: flex;
    flex-direction: row;
    display: grid;
    grid-template-columns: 33% 33% 33%;

    .payout-blocks__block-desktop {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      max-width: 240px;
      min-height: 120px;
      margin-right: 5px;
      margin-bottom: 5px;
      background-color: $block-background;
      border-radius: 10px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
      .payout-blocks__month-desktop {
        margin-bottom: 16px;
        text-transform: uppercase;
      }
      .payout-blocks__amount-desktop {
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 32px;
        font-family: $barlow-condensed;
        line-height: 1.25px;
      }
    }
  }
}


.payout-blocks__container .Carousel__itemsList {
  .Carousel__slide.payout-blocks__block {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 120px;
    margin-right: 5px;
    background-color: #3a3b3e;
    min-width: inherit;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
    width: calc(33% - 5px);
    a {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      color: white;
      text-decoration: none;
    }
  }
}
