@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('OpenSans-Regular.eot');
  src: local('Open Sans'), local('OpenSans'),
    url('OpenSans-Regular.eot') format('embedded-opentype'),
    url('OpenSans-Regular.woff') format('woff'),
    url('OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('OpenSans-Light.eot') format('embedded-opentype'),
    url('OpenSans-Light.woff') format('woff'),
    url('OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('OpenSans-Bold.eot') format('embedded-opentype'),
    url('OpenSans-Bold.woff') format('woff'),
    url('OpenSans-Bold.ttf') format('truetype');
}
