@import '../../global/variables.scss';

@mixin section-style($color) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 65px;
  > :first-child {
    @include stats-number;
    margin-bottom: 0px;
    margin-top: 0px;
    color: $color;
  }
  > :nth-child(2) {
    margin-top: $small-margin;
    text-transform: uppercase;
  }
}

.user-payout__container {
  display: flex;
  flex-direction: column;
  margin-bottom: $large-margin;
  margin-top: $large-margin;

  .user-payout__container-grid {
    display: flex;
    justify-content: flex-start;
    @media (min-width: 1199px) {
      margin-top: -24px;
    }
    .user-payout__container-grid-left {
      padding-right: $medium-margin;
      border-right: solid 1px $divider-color;
    }
    .user-payout__container-grid-center {
      padding-left: $medium-margin;
      padding-right: $medium-margin;
      border-right: solid 1px $divider-color;
    }
    .user-payout__container-grid-right {
      padding-left: $medium-margin;
    }

    .number-green {
      @include section-style($number-green);
    }

    .number-blue {
      @include section-style($number-blue);
    }

    .number-white {
      @include section-style('white');
    }
  }
}
