@import '../../global/variables.scss';

.fitplan-logo {
  display: flex;
  flex-direction: column;
  margin-left: calc(50% - 17px);
  z-index: 10;
  background: none;
  border: none;
  @media (min-width: $for-desktop-up) {
    margin-left: 0px;
  }
}
